<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form data-vv-scope="announcementForm" method="post">
      <div class="row justify-content-md-center p-3">

        <div class="col-12 col-md-12">
          <div class="card">
            <div class="card-body">
              <h5 class="box-title mb-3">{{ $t('form.cupom.section.periodo') }}</h5>
              <div class="row">
                <!--  Período -->
                <div class="col-12 col-md-6">
                  <label>{{ this.$i18n.t('form.announcement.beginAt') }}</label>
                  <md-datepicker name="formData.beginAt" v-model="formData.beginAt"
                    md-immediately v-validate="{ required: true }"/>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('formData.beginAt')"
                      class="royalc-error-field">{{ errors.first('formData.beginAt') }}</small>
                  </transition>
                </div>
                <div class="col-12 col-md-6">
                  <label>{{ this.$i18n.t('form.announcement.finishAt') }}</label>
                  <md-datepicker name="formData.finishAt" v-model="formData.finishAt"
                                 md-immediately v-validate="{ required: true }"/>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('formData.finishAt')"
                           class="royalc-error-field">{{ errors.first('formData.finishAt') }}</small>
                  </transition>
                </div>
              </div>

              <!--  Descrição -->
              <h5 class="box-title mb-3">{{ $t('form.announcement.description') }}</h5>
              <div class="row">
                <div class="col-12 col-md-12">
                  <md-field>
                    <md-textarea name="formData.description" v-model="formData.description" md-counter="256" v-validate="{ required: true }" ></md-textarea>
                  </md-field>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('formData.description')"
                           class="royalc-error-field">{{ errors.first('formData.description') }}</small>
                  </transition>
                </div>
              </div>

              <!--  Conteúdo -->
              <h5 class="box-title mb-3">{{ $t('form.announcement.content_html') }}</h5>
              <div class="row">
                <div class="col-12 col-md-12">
                  <ckeditor name="content_html" v-model="formData.content_html" :config="editorConfig" v-validate="{ required: true }"></ckeditor>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('formData.description')"
                           class="royalc-error-field">{{ errors.first('formData.description') }}</small>
                  </transition>
                </div>
              </div>

              <!--  Apenas quando -->
              <h5 class="box-title mb-3">{{ $t('form.announcement.show_only_to') }}</h5>
              <div class="row">
                <div class="col-12 col-md-12">
                  <md-checkbox v-model="formData.allowOnlineProposal">
                    Propostas Online
                    <i class="ft-alert-circle"></i><md-tooltip md-direction="right">
                      Somente será exibido para usuários com permissão de acesso a Cotações Online
                  </md-tooltip>
                  </md-checkbox>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- Submit -->
        <div class="col-12">
          <button type="button" class="btn btn btn-outline-light pull-right" @click="onSave">Salvar</button>
          <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">Cancelar</button>
        </div>

      </div>
    </form>
  </div>
</template>

<script>
// Components

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import AnnouncementService from '@/services/AnnouncementService'

import Vue from 'vue'
import CKEditor from 'ckeditor4-vue'

Vue.use(CKEditor)

export default {
  name: 'AnnoucementForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.announcement') + ' - %s'
    }
  },
  data () {
    return {
      // Configuration to mount table of selected values
      formData: {
        description: null,
        beginAt: null,
        finishAt: null,
        content_html: null,
        allowOnlineProposal: null
      },
      formErrors: {},
      isLoading: true,
      fullPage: true,
      editorConfig: {
        // Cores disponíveis
        fontSize_sizes: '14/1.1em;16/1.2em;20/1.5em;',
        colorButton_colors: '7f683b,333,555,CCC,DDD,FFF',
        filebrowserUploadUrl: process.env.VUE_APP_APIENDPOINT + 'v2/file/upload/wysiwyg?command=QuickUpload&type=Files',
        filebrowserImageUploadUrl: process.env.VUE_APP_APIENDPOINT + 'v2/file/upload/wysiwyg?command=QuickUpload&type=Images',
        fileTools_requestHeaders: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        },
        height: 450,
        format_tags: 'p;h1;h2;h3;pre',
        format_h1: { element: 'h1', styles: { 'font-size': '20px', 'line-height': '30px', 'font-weight': 'bold', 'margin-top': '20px', 'margin-bottom': '20px', 'font-family': '\'Helvetica Neue\',Helvetica,Arial,sans-serif' }, attributes: { 'class': 'title' } },
        format_p: { element: 'p', styles: { 'font-size': '16px', 'line-height': '24px', 'margin-top': '16px', 'margin-bottom': '16px', 'font-family': '\'Helvetica Neue\',Helvetica,Arial,sans-serif' } },
        extraPlugins: ['mentions', 'uploadimage', 'colorbutton', 'font'],
        stylesSet: 'my_styles:/static/js/styles.js',
        fontFamily: {
          options: [
            'Helvetica,Sans-Serif'
          ]
        },
        toolbarGroups: [
          { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
          { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
          { name: 'forms', groups: [ 'forms' ] },
          { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
          { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
          { name: 'links', groups: [ 'links' ] },
          { name: 'insert', groups: [ 'insert' ] },
          { name: 'styles', groups: [ 'styles' ] },
          { name: 'colors', groups: [ 'colors' ] },
          { name: 'tools', groups: [ 'tools' ] },
          { name: 'others', groups: [ 'others' ] },
          { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
          { name: 'about', groups: [ 'about' ] }
        ],
        removeButtons: 'Font,Maximize,Anchor,About,Scayt,NewPage,Save,Preview,Print,Templates,Cut,Copy,Paste,PasteText,Find,Replace,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,CopyFormatting,CreateDiv,BidiRtl,BidiLtr,Language,Flash,Smiley,SpecialChar,Iframe,About',
        mentions: [
          {
            feed: this.variableList,
            marker: '{{',
            minChars: 0,
            itemTemplate: '<li data-id="{id}">' +
              '{label}' +
              '</li>',
            outputTemplate: '{name} | raw}}'
          }
        ]
      },
      // List of options to select on select input
      submitted: false
    }
  },
  components: {
    Loading
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    // this.$store.dispatch('SetShowFilter', true)
    // this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    _this.isLoading = true

    // If is edit populate values
    let id = this.$route.params.id

    if (id) {
      AnnouncementService.getAnnouncement(id).then((res) => {
        _this.formData = res.data
        _this.formData.beginAt = new Date(res.data.beginAt)
        _this.formData.finishAt = new Date(res.data.finishAt)
      }).finally(() => {
        _this.isLoading = false
      })
    } else {
      _this.isLoading = false
    }
  },
  methods: {
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'AnnouncementIndex' })
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validateAll('announcementForm').then(valid => {
        if (valid) {
          _this.isLoading = true

          let data = Object.assign({}, _this.formData)

          let id = this.$route.params.id
          if (id) {
            AnnouncementService.editAnnouncement(id, data).then(response => {
              _this.$router.push({ name: 'AnnouncementIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          } else {
            AnnouncementService.newAnnouncement(data).then(response => {
              _this.$router.push({ name: 'AnnouncementIndex' })
            }).finally(() => {
              _this.isLoading = false
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;

  }
</style>
